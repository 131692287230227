import "./styles.scss";

import React from "react";
import { withPrefix } from "gatsby";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

import mapStyle from "utils/mapStyle";

const mainClass = "home-location__circle__content__map";

const Map = () => {
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: "AIzaSyBgWUTjUd7zCbFNupxHXHRKgZZgp94dfNk",
	});
	const center = {
		lat: 53.794,
		lng: 21.581,
	};

	return (
		<div className={mainClass}>
			{isLoaded ? (
				<GoogleMap
					center={center}
					zoom={15}
					options={{ styles: mapStyle }}
				>
					<Marker
						icon={withPrefix("/ico-pointer.svg")}
						position={{
							lat: 53.79131546226561,
							lng: 21.585046084656316,
						}}
					/>
				</GoogleMap>
			) : null}
		</div>
	);
};

export default Map;
