import "./styles.scss";

import React from "react";
import classnames from "classnames";

// import CondoIcon from "icons/condo.svg";

const mainClass = "home-operator";

const Operator = () => {
	return (
		<section id="operator-section" className={mainClass}>
			<div className="container">
				<h2
					className={classnames(
						"section-header",
						"section-header--light",
						"section-header--center"
					)}
				>
					Operator
				</h2>
				<p>
					Po otwarciu obiektu wszystkie działania operacyjne będą
					prowadzone przez renomowaną grupę hotelową posiadającą
					bogate doświadczenie oraz szerokie portfolio.
				</p>
				<p className="small">
					Operator ten będzie odpowiedzialny za nadzór nad wszystkimi
					procesami w hotelu, pokrywanie wszelkich kosztów związanych
					z marketingiem oraz zapewnienie odpowiedniego obłożenia.
					Dzięki temu właściciel lokalu nie musi martwić się o
					maksymalizację zysku z najmu, a inwestycja staje się w pełni
					bezobsługowa.
				</p>
				{/* <CondoIcon /> */}
			</div>
			<div className={`${mainClass}__circle`} />
		</section>
	);
};

export default Operator;
