import "./styles.scss";

import React from "react";
import { Fade } from "react-awesome-reveal";

import aboutInvestment1Image from "images/about-investment1-v2.jpg";
import aboutInvestment2Image from "images/about-investment2-v2.jpg";
import aboutInvestment3Image from "images/about-investment3-v2.jpg";

import WavesIcon from "icons/waves-icon.svg";

const mainClass = "home-about-investment";

const items_data = [
	{
		icon: <WavesIcon />,
		label: "przy linii Brzegovej jeziora",
	},
	{
		label: "apartamentów",
		value: "186",
	},
	{
		label: "dostępne metraże",
		value: (
			<>
				29-54 m<sup>2</sup>
			</>
		),
	},
	{
		label: "miejsc parkingowych",
		value: "180",
	},
];

const AboutInvestment = () => {
	return (
		<section id="o-inwestycji-section" className={mainClass}>
			<div className="container">
				<h2 className="section-header">O inwestycji</h2>
				<div className={`${mainClass}__content`}>
					<div className={`${mainClass}__content__left`}>
						<p>
							Brzegova Residence to urzekająca inwestycja w
							pięciogwiazdkowym standardzie, której położenie jest
							jednocześnie prestiżowe i urokliwe.
						</p>
						<p className="small">
							Zlokalizowana w pierwszej linii brzegowej jeziora
							Mikołajskiego, czyli mazurskiej Wenecji, jak
							określane są Mikołajki, oferuje 186 apartamentów o
							zróżnicowanych metrażach (29,81-54,40 m2) oraz 180
							miejsc parkingowych.
						</p>
						<div className={`${mainClass}__content__left__image`}>
							<Fade direction="up" triggerOnce>
								<img src={aboutInvestment1Image} alt="" />
							</Fade>
						</div>
						<div className={`${mainClass}__content__left__image`}>
							<Fade direction="up" delay={250} triggerOnce>
								<img src={aboutInvestment2Image} alt="" />
							</Fade>
						</div>
					</div>
					<div className={`${mainClass}__content__right`}>
						<div className={`${mainClass}__content__right__items`}>
							{items_data?.map(
								({ label, value, icon }, index) => (
									<div
										key={index}
										className={`${mainClass}__content__right__items__item`}
									>
										{!!icon && icon}
										<h2>{value}</h2>
										<span>{label}</span>
									</div>
								)
							)}
						</div>
						<p>
							Wyjątkowe otoczenie obiektu, bliskość plaży
							miejskiej oraz mnogość udogodnień sprawiają, że
							Brzegova Residence stanowi doskonałe miejsce zarówno
							dla miłośników aktywnego wypoczynku, jak i osób
							poszukujących wytchnienia i komfortu.
						</p>
						<div className={`${mainClass}__content__right__image`}>
							<Fade direction="up" delay={500} triggerOnce>
								<img src={aboutInvestment3Image} alt="" />
							</Fade>
						</div>
						<p className="small">
							Brzegova Residence to nienachalny prestiż,
							naturalnie wkomponowany w otaczający inwestycję
							krajobraz. Poczucie harmonijnej integracji z naturą
							potęgują duże okna, a znajdujące się na terenie
							inwestycji basen infinity, strefa SPA z jacuzzi,
							sauną oraz basenem wewnętrznym, dopełniają
							idylliczny charakter tego bez wątpienia wyjątkowego
							miejsca.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutInvestment;
