import "./styles.scss";

import React from "react";

const mainClass = "home-baner";

const Baner = () => {
  return (
    <section className={mainClass}>
      <div className="container">
        <h3 className="section-header">
          Brzegova Residence to urzekająca inwestycja w<br />
          pięciogwiazdkowym standardzie
        </h3>
      </div>
    </section>
  );
};

export default Baner;
