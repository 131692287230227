import "./styles.scss";

import React from "react";
import classnames from "classnames";

import businessModelImage from "images/business-model.jpg";

const mainClass = "home-business-model";

const BusinessModel = () => {
  return (
    <section id="model-biznesowy-section" className={mainClass}>
      <div className="container">
        <h2 className={classnames("section-header", "section-header--light")}>
          Model biznesowy
        </h2>
        <div className={`${mainClass}__content`}>
          <div className={`${mainClass}__content__left`}>
            <h3>
              Podział zysków
              <br />w modelu 70/30
            </h3>
            <p>
              Co ważne, nie będziesz zaangażowany w obsługę najmu i bieżące
              zarządzanie obiektem. Zyski dzielone są w proporcjach 70%
              Inwestor, 30% Operator. Wynagrodzenie operatora pokrywa koszty
              związane z bieżącą obsługą najmu, pozyskiwaniem gości, promocją
              oraz zarządzaniem obiektem w całości.
            </p>
          </div>
          <div className={`${mainClass}__content__right`}>
            <img src={businessModelImage} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessModel;
