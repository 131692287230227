/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import "react-image-lightbox/style.css";
import "./styles.scss";

import React, { useRef, useState } from "react";
import Lightbox from "react-image-lightbox";
import Slider from "react-slick";

import ArrowButton from "components/ArrowButton";

import Slide1 from "images/gallery/2_VIEW.jpg";
import Slide2 from "images/gallery/3_VIEW.jpg";
import Slide3 from "images/gallery/7_VIEW.jpg";
import Slide4 from "images/gallery/8_VIEW.jpg";
import Slide5 from "images/gallery/11_VIEW.jpg";
import Slide6 from "images/gallery/24_VIEW.jpg";
import Slide7 from "images/gallery/27_VIEW.jpg";
import Slide8 from "images/gallery/29_VIEW.jpg";
import Slide9 from "images/gallery/brzegova-rest1.jpg";
import Slide10 from "images/gallery/brzegova-rest2.jpg";
import Slide11 from "images/gallery/brzegova-rest3.jpg";

const mainClass = "home-gallery";

const items = [
	Slide1,
	Slide2,
	Slide3,
	Slide4,
	Slide5,
	Slide6,
	Slide7,
	Slide8,
	Slide9,
	Slide10,
	Slide11,
];

const CustomArrow = (props) => {
	const { className, onClick } = props;
	
	return (
		<button className={className} onClick={onClick}>
			<ArrowButton />
		</button>
	);
};

const Gallery = () => {
	const slider = useRef();

	const [current_slide, setCurrentSlide] = useState(0);
	const [selected_photo, setSelectedPhoto] = useState(null);

	const slider_settings = {
		arrows: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 500,
		beforeChange: (oldIndex, newIndex) => {
			setCurrentSlide(newIndex);
		},
	};

	return (
		<section id="galeria-section" className={mainClass}>
			<div className="container">
				<div className={`${mainClass}__content`}>
					<div className={`${mainClass}__content__left`}>
						<h2 className="section-header">Galeria</h2>
					</div>
					<div className={`${mainClass}__content__right`}>
						<Slider
							ref={slider}
							className={`${mainClass}__content__right__slider`}
							{...slider_settings}
						>
							{items?.map((item, index) => (
								<div key={index}>
									<div
										className={`${mainClass}__content__right__slider__item`}
										onClick={(e) => {
											e.preventDefault();
											setSelectedPhoto(index);
										}}
										style={{
											backgroundImage: `url(${item})`,
										}}
									/>
								</div>
							))}
						</Slider>
						<div
							className={`${mainClass}__content__right__slider__navigation`}
						>
							<div
								className={`${mainClass}__content__right__slider__navigation__buttons`}
							>
								<CustomArrow
									className={`${mainClass}__content__right__slider__navigation__arrow ${mainClass}__content__right__slider__navigation__arrow--prev`}
									onClick={() => slider?.current?.slickPrev()}
								/>
								<CustomArrow
									className={`${mainClass}__content__right__slider__navigation__arrow ${mainClass}__content__right__slider__navigation__arrow--next`}
									onClick={() => slider?.current?.slickNext()}
								/>
							</div>
							<h4>{`${current_slide + 1}/${items?.length}`}</h4>
						</div>
					</div>
				</div>
			</div>
			{selected_photo !== null && (
				<Lightbox
					mainSrc={items[selected_photo]}
					nextSrc={items[(selected_photo + 1) % items.length]}
					prevSrc={
						items[
							(selected_photo + items.length - 1) % items.length
						]
					}
					onCloseRequest={() => setSelectedPhoto(null)}
					onMovePrevRequest={() =>
						setSelectedPhoto(
							(selected_photo + items.length - 1) % items.length
						)
					}
					onMoveNextRequest={() =>
						setSelectedPhoto((selected_photo + 1) % items.length)
					}
				/>
			)}
		</section>
	);
};

export default Gallery;
