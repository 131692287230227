import "./styles.scss";

import React, { useState } from "react";
import classnames from "classnames";

import Map from "./components/Map";
import Button from "components/Button";
import Slider from "./components/Slider";

const mainClass = "home-location";

const Location = () => {
  const [state, setState] = useState(0);

  return (
    <section id="lokalizacja-section" className={mainClass}>
      <div className="container">
        <h2
          className={classnames(
            "section-header",
            "section-header--light",
            "section-header--center"
          )}
        >
          Lokalizacja
        </h2>
        <p>
          Brzegova Residence znajduje się w pierwszej linii brzegowej Jeziora
          Mikołajskiego z bezpośrednim dostępem do promenady w Mikołajkach na
          ulicy Michała Kajki. Mikołajki to malownicza miejscowość, nazywana
          mazurską Wenecją i żeglarską stolicą Polski, położona jest nad
          jeziorami Tałty i Mikołajskim na szlaku Wielkich Jezior Mazurskich.
        </p>
        <p className="small">
          Teren wyróżnia się niepowtarzalną przyrodą i niezwykle czystym
          powietrzem. Sama inwestycja zlokalizowana jest w miejscu doskonale
          łączącym bezpośrednią bliskość natury oraz liczne atrakcje takie jak
          restauracje, plaża miejska czy promenada.
        </p>
        <div className={`${mainClass}__circle`}>
          <div className={`${mainClass}__circle__wrapper`}>
            <div className={`${mainClass}__circle__content`}>
              {!!state ? <Slider /> : <Map />}
            </div>
          </div>
          <Button onClick={() => setState((prev) => !prev)}>
            {!!state ? "Mapa lokalizacji" : "Zdjęcia okolicy"}
          </Button>
          <div className={`${mainClass}__circle__address`}>
            <span className="small">
              Michała Kajki 124
              <br />
              11-730 Mikołajki
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Location;
