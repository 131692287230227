import "./styles.scss";

import React from "react";
import ReactPlayer from "react-player";

import ContactForm from "components/ContactForm";

const HeroVideo = () => {
	return (
		<section className="home-hero-video">
			<div className="home-hero-video__video">
				<div className="embed-container">
					<ReactPlayer
						url={require("videos/hero-video.mp4").default}
						width="100%"
						height="100%"
						playing={true}
						loop={true}
						muted={true}
						playsinline
					/>
				</div>
			</div>

			<div className="home-hero-video__wrapper">
				<div className="home-hero-video__left">
					<h1>
						Przystań w pięciogwiazdkowym
						<br /> standardzie
					</h1>
				</div>
				<div className="home-hero-video__right">
					<ContactForm
						header="Wypełnij formularz, a my skontaktujemy się z Tobą i przedstawimy więcej szczegółów."
						heroVersion
					/>
				</div>
				<div className="home-hero-video__image">
					<img
						src={require("images/gallery/2_VIEW.jpg").default}
						alt=""
					/>
				</div>
			</div>
		</section>
	);
};

export default HeroVideo;
