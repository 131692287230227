import "./styles.scss";

import React from "react";

import FeaturesGrid from "components/FeaturesGrid";

import SpaIcon from "icons/facilities/spa.svg";
import BeachIcon from "icons/facilities/beach.svg";
import RestaurantIcon from "icons/facilities/restaurant.svg";
import CoffeeIcon from "icons/facilities/coffee.svg";
import GrillIcon from "icons/facilities/grill.svg";
import ConferenceIcon from "icons/facilities/conference.svg";
import SwimmingPoolIcon from "icons/facilities/swimming-pool.svg";
import SunsetIcon from "icons/facilities/sunset.svg";
import GymIcon from "icons/facilities/gym.svg";
import KregleIcon from "icons/facilities/kregle.svg";

const mainClass = "home-facilities";

const data = [
	{
		icon: <SpaIcon />,
		text: "Strefa SPA z jacuzzi, saunami<br/>i basenem wewnętrznym",
	},
	{
		icon: <BeachIcon />,
		text: "Plaża",
	},
	{
		icon: <RestaurantIcon />,
		text: "Restauracja",
	},
	{
		icon: <CoffeeIcon />,
		text: "Kawiarnia",
	},
	{
		icon: <GrillIcon />,
		text: "Chata grillowa",
	},
	{
		icon: <ConferenceIcon />,
		text: "Sale konferencyjne",
	},
	{
		icon: <SwimmingPoolIcon />,
		text: "Basen zewnętrzny infinity",
	},
	{
		icon: <SunsetIcon />,
		text: "Promenada nad brzegiem jeziora",
	},
	{
		icon: <GymIcon />,
		text: "Siłownia",
	},
	{
		icon: <KregleIcon />,
		text: "Kręgielnia",
	},
];

const Facilities = () => {
	return (
		<section id="udogodnienia-section" className={mainClass}>
			<div className="container">
				<h2 className="section-header">Udogodnienia</h2>
				<FeaturesGrid data={data} />
			</div>
		</section>
	);
};

export default Facilities;
