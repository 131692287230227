import "./styles.scss";

import React from "react";

import Button from "components/Button";

const Apartments = () => {
	return (
		<section className="home-apartments" id="apartamenty-section">
			<div className="container">
				<div className="home-apartments__wrapper">
					<h1>Wybierz apartamenty</h1>
					<Button href="/apartamenty/">Dowiedz się więcej</Button>
				</div>
			</div>
		</section>
	);
};

export default Apartments;
