import "./styles.scss";

import React, { useRef, useState } from "react";
import classnames from "classnames";
import useClickOutside from "utils/useClickOutside";

import ArrowIcon from "icons/arrow.svg";

const mainClass = "custom-select";

const Select = ({ options, onChange, variant }) => {
  const ref = useRef();
  const [is_open, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(options?.[0]);

  useClickOutside(ref, () => setIsOpen(false));

  const onChangeHandler = (value) => {
    setSelected(value);
    onChange(value);
    setIsOpen(false);
  };

  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--${variant}`]: !!variant,
        [`${mainClass}--active`]: !!is_open,
      })}
      ref={ref}
    >
      <div className={`${mainClass}__wrapper`}>
        <button
          type="button"
          className={`${mainClass}__button`}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <ArrowIcon />
          {selected?.label}
          <ArrowIcon />
        </button>
        {!!is_open && (
          <div className={`${mainClass}__list`}>
            {options?.map(({ label, value }, index) => (
              <div
                key={index}
                className={classnames(`${mainClass}__list__item`, {
                  [`${mainClass}__list__item--active`]:
                    selected?.value === value,
                })}
                onClick={() => onChangeHandler({ label, value })}
              >
                <span>{label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Select;
