import "./styles.scss";

import React, { useState } from "react";

import Select from "components/Select";

const mainClass = "home-calculator";

const apartments_data = [
	{
		id: 1,
		title: "A 1.07",
		rooms: "3",
		area: "47,86 m2",
		days: 14,
		price: "1 124 710,00 zł",
		profit: "90 763,18 zł",
		plan: require("images/calc-local-1.jpg").default,
	},
	{
		id: 2,
		title: "B 2.05",
		rooms: "2",
		area: "36,66 m2",
		days: 14,
		price: "843 180,00 zł",
		profit: "67 954,85 zł",
		plan: require("images/calc-local-2.jpg").default,
	},
	{
		id: 3,
		title: "C 1.04",
		rooms: "2",
		area: "29,81 m2",
		days: 14,
		price: "563 409,00 zł",
		profit: "44 640,96 zł",
		plan: require("images/calc-local-3.jpg").default,
	},
];

const Calculator = () => {
	const [selected_option, setSelectedOption] = useState(1);

	const selected_apartment = apartments_data?.find(
		(item) => item?.id === selected_option
	);

	const data_items = [
		{
			label: "Liczba pokoi",
			value: selected_apartment?.rooms,
		},
		{
			label: "Powierzchnia apartamentu",
			value: selected_apartment?.area,
		},
		{
			label: "Liczba dni pobytu właścicielskiego",
			value: selected_apartment?.days,
		},
		{
			label: "Cena netto",
			value: selected_apartment?.price,
		},
	];

	return (
		<section id="lokalizacja-section" className={mainClass}>
			<div className="container">
				<h2 className="section-header">Przykładowe apartamenty</h2>
				<div className={`${mainClass}__content`}>
					<div className={`${mainClass}__content__left`}>
						<div className={`${mainClass}__content__left__action`}>
							<span>Wybierz apartament</span>
							<Select
								options={apartments_data?.map(({ title, id }) => ({
									label: title,
									value: id,
								}))}
								onChange={(e) => setSelectedOption(e?.value)}
							/>
						</div>
						<div className={`${mainClass}__content__left__items`}>
							{data_items?.map(({ label, value }, index) => (
								<div
									key={index}
									className={`${mainClass}__content__left__items__item`}
								>
									<span className="small">{label}</span>
									<h4>{value}</h4>
								</div>
							))}
						</div>
						<div className={`${mainClass}__content__left__profit`}>
							<span>Zyski dzielone są w proporcjach</span>
							<h3>70/30</h3>
						</div>
					</div>
					<div className={`${mainClass}__content__right`}>
						<img src={selected_apartment?.plan} alt="" />
					</div>
				</div>
				{/* <div className={`${mainClass}__info`}>
					Kalkulacja uwzględnia koszty: obsługi płatności i transakcji
					internetowych, prowizji podmiotów trzecich, takich jak:
					portale rezerwacyjne, biura podróży, pośrednicy turystyczni
					i tym podobnych. W trakcie trwania umowy najmu właściciel
					ponosi koszty: ubezpieczenia lokalu, podatku od
					nieruchomości oraz kosztów utrzymania lokalu. Niniejsze
					informacje nie stanowią oferty w rozumienie Art. 66 § 1
					Kodeksu Cywilnego i nie stanowią gwarancji uzyskania
					podobnych wyników oraz świadczeń w przyszłości. Szczegóły
					dostępne są w biurze sprzedaży.
				</div> */}
			</div>
		</section>
	);
};

export default Calculator;
