import React from "react";

import Layout from "components/Layout";
import Seo from "../components/Layout/components/Seo";
import {
	HeroVideo,
	AboutInvestment,
	Location,
	Facilities,
	BusinessModel,
	Gallery,
	Steps,
	Calculator,
	Baner,
	WhyIsWorth,
	Operator,
	Apartments,
} from "page_components/home";

const Home = () => {
	return (
		<Layout isHome>
			<HeroVideo />
			<AboutInvestment />
			<Apartments />
			<Location />
			<Facilities />
			<Gallery />
			<BusinessModel />
			<Steps />
			<Calculator />
			<Baner />
			<WhyIsWorth />
			<Operator />
		</Layout>
	);
};

export const Head = () => <Seo title="Strona główna | Brzegova" />;

export default Home;
