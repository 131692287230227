import "react-image-lightbox/style.css";
import "./styles.scss";

import React, { useRef, useState } from "react";
import Lightbox from "react-image-lightbox";
import Slider from "react-slick";

import ArrowButton from "components/ArrowButton";

import Slide1 from "images/location/zdjecie_1.jpg";
import Slide2 from "images/location/zdjecie_2.jpg";

const mainClass = "home-location__circle__content__gallery";

const items = [Slide1, Slide2];

const CustomArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button className={className} onClick={onClick}>
      <ArrowButton variant="blue" />
    </button>
  );
};

const LocationSlider = () => {
  const slider = useRef();

  const [selected_photo, setSelectedPhoto] = useState(null);

  const slider_settings = {
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
  };

  return (
    <div className={mainClass}>
      <Slider
        ref={slider}
        className={`${mainClass}__slider`}
        {...slider_settings}
      >
        {items?.map((item, index) => (
          <div key={index}>
            <div
              className={`${mainClass}__slider__item`}
              onClick={(e) => {
                e.preventDefault();
                setSelectedPhoto(index);
              }}
              style={{
                backgroundImage: `url(${item})`,
              }}
            />
          </div>
        ))}
      </Slider>
      <div className={`${mainClass}__slider__navigation`}>
        <CustomArrow
          className={`${mainClass}__slider__navigation__arrow ${mainClass}__slider__navigation__arrow--prev`}
          onClick={() => slider?.current?.slickPrev()}
        />
        <CustomArrow
          className={`${mainClass}__slider__navigation__arrow ${mainClass}__slider__navigation__arrow--next`}
          onClick={() => slider?.current?.slickNext()}
        />
      </div>
      {selected_photo !== null && (
        <Lightbox
          mainSrc={items[selected_photo]}
          nextSrc={items[(selected_photo + 1) % items.length]}
          prevSrc={items[(selected_photo + items.length - 1) % items.length]}
          onCloseRequest={() => setSelectedPhoto(null)}
          onMovePrevRequest={() =>
            setSelectedPhoto((selected_photo + items.length - 1) % items.length)
          }
          onMoveNextRequest={() =>
            setSelectedPhoto((selected_photo + 1) % items.length)
          }
        />
      )}
    </div>
  );
};

export default LocationSlider;
