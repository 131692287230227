import "./styles.scss";

import React from "react";
import classnames from "classnames";

import FeaturesGrid from "components/FeaturesGrid";

import ChartIcon from "icons/facilities/chart.svg";
import CalendarIcon from "icons/facilities/calendar.svg";
import LuggageIcon from "icons/facilities/luggage.svg";
import PawnIcon from "icons/facilities/pawn.svg";
import CourtIcon from "icons/facilities/court.svg";
import DollarIcon from "icons/facilities/dollar.svg";
import BuildingsIcon from "icons/facilities/buildings.svg";

const mainClass = "home-why-is-worth";

const data = [
	{
		icon: <ChartIcon />,
		text: "Zarabiasz na wzroście wartości<br/>nieruchomości",
	},
	{
		icon: <CalendarIcon />,
		text: "Zarabiasz na wynajmie",
	},
	{
		icon: <LuggageIcon />,
		text: "Nieograniczony pobyt<br/>właścicielski poza sezonem",
	},
	{
		icon: <PawnIcon />,
		text: "Doświadczony operator",
	},
	{
		icon: <CourtIcon />,
		text: "Pełna własność apartamentu /<br/>księga wieczysta",
	},
	{
		icon: <DollarIcon />,
		text: "Pełny zwrot podatku VAT także od<br/>osób fizycznych",
	},
	{
		icon: <BuildingsIcon />,
		text: "Stabilny i doświadczony<br/>deweloper",
	},
];

const WhyIsWorth = () => {
	return (
		<section id="dlaczego-warto-section" className={mainClass}>
			<div className="container">
				<h2
					className={classnames(
						"section-header",
						"section-header--center"
					)}
				>
					Dlaczego warto?
				</h2>
				<FeaturesGrid data={data} />
			</div>
		</section>
	);
};

export default WhyIsWorth;
