import "./styles.scss";

import React from "react";
import classnames from "classnames";

import StepsEarnImage from "images/steps-earn.jpg";
import StepsRestImage from "images/steps-rest.jpg";

const mainClass = "home-steps";

const data = [
  {
    header: "Kup",
    text: "Zainwestuj w apartament w sercu żeglarskiej stolicy Polski - Mikołajkach.",
  },
  {
    header: "Zarabiaj",
    text: "Zarabiaj na wzroście wartości nieruchomości oraz czerp zyski z bezobsługowego wynajmu, którym zajmiemy się za Ciebie.",
    variant: "gold",
    image: StepsEarnImage,
  },
  {
    header: "Wypoczywaj",
    text: "Wypoczywaj w pięciogwiazdkowym standardzie w urzekającym zakątku Polski.",
    variant: "blue",
    image: StepsRestImage,
  },
];

const Steps = () => {
  return (
    <section className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__content`}>
          <div className={`${mainClass}__content__items`}>
            {data?.map(({ header, text, variant, image }, index) => (
              <div
                key={index}
                className={classnames(`${mainClass}__content__items__item`, {
                  [`${mainClass}__content__items__item--${variant}`]: !!variant,
                })}
              >
                <div className={`${mainClass}__content__items__item__text`}>
                  <h3>{header}</h3>
                  <p>{text}</p>
                </div>
                {!!image && <img src={image} alt="" />}
              </div>
            ))}
          </div>
          <p>
            Brzegova Residence to apartamenty inwestycyjne. Znaczy to dokładnie
            tyle, że dokonując inwestycji, zyskujesz w dwóch kluczowych
            obszarach. Po pierwsze stajesz się właścicielem apartamentu w
            prestiżowej inwestycji, w fenomenalnej lokalizacji - wypoczywasz,
            więc nie tylko w komfortowych warunkach, ale pozyskujesz miejsce na
            weekendowe czy wakacyjne wypady z rodziną i przyjaciółmi. Po drugie,
            zarabiasz dzięki zyskom z wynajmu Twojego apartamentu podczas Twojej
            nieobecności.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Steps;
